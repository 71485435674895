exports.onClientEntry = () => {
  // Add a polyfill for CSS custom properties to IE browsers
  // repo: https://github.com/nuxodin/ie11CustomProperties#readme
  if (window.MSInputMethodContext && document.documentMode) {
    const customPropertiesPolyfillScript = document.createElement("script");
    customPropertiesPolyfillScript.src =
      "https://cdn.jsdelivr.net/gh/nuxodin/ie11CustomProperties@4.1.0/ie11CustomProperties.min.js";
    document.head.appendChild(customPropertiesPolyfillScript);
  }
};
