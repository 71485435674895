import classnames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import capitalise from "../../utils/capitalise";
import * as styles from "./Section.module.css";
import ParallaxImage from "../parallaxImage/ParallaxImage";

const Section = ({
  className,
  id,
  style,
  padding,
  backgroundGradient,
  backgroundGradientWhite,
  backgroundGradientHeader,
  backgroundImage,
  backgroundAlt,
  backgroundImageSrc,
  backgroundVideo,
  mobileBackgroundVideo,
  backgroundColor,
  mobileNoBackgroundImage,
  mobileBackgroundImage,
  header,
  headerBlog,
  children,
  childrenBefore,
  childrenAfter,
  noInner,
  innerMargin,
  divide,
  divider,
  lightOnDark,
  darkOnLight,
  showcase,
  hero,
  roundedBottomRight,
  fullHeight,
  verticalCenter,
  verticalEnd,
  sticky,
  dynamic,
  heroContent,
  spaceBetween,
  flexFix
}) => {
  const classes = classnames(styles.section, {
    [styles.paddingTop]: padding === "top" || padding === "both",
    [styles.paddingBottom]: padding === "bottom" || padding === "both",
    [styles.backgroundGradient]: backgroundGradient,
    [styles.backgroundImageSection]: backgroundImage,
    [styles.backgroundGradientWhite]: backgroundGradientWhite,
    [styles.backgroundGradientHeader]: backgroundGradientHeader,
    [styles.mobileNoBackgroundImage]: mobileNoBackgroundImage,
    [styles.mobileBackgroundImage]: mobileBackgroundImage,
    [styles.mobileBackgroundVideo]: mobileBackgroundVideo,
    [styles.header]: header,
    [styles.headerBlog]: headerBlog,
    [styles.innerMarginBottom]: innerMargin === "bottom",
    [`lightOnDark`]: lightOnDark,
    [`darkOnLight`]: darkOnLight,
    [styles.showcase]: showcase,
    [styles.hero]: hero,
    [styles.roundedBottomRight]: roundedBottomRight,
    [styles.fullHeight]: fullHeight && fullHeight !== "desktop",
    [styles.fullHeightDesktop]: fullHeight === "desktop",
    [styles.verticalCenter]: verticalCenter,
    [styles.verticalEnd]: verticalEnd,
    [styles.sticky]: sticky,
    [styles.divideRightToLeft]: divide === "rightToLeft",
    [styles.divideRightToLeftTop]: divide === "rightToLeftTop",
    [styles.divideLeftToRight]: divide === "leftToRight",
    [styles.divideLeftToRightTop]: divide === "leftToRightTop",
    [styles.divideLeftToRightBottom]: divide === "leftToRightBottom",
    [`background${capitalise(backgroundColor)}`]: backgroundColor,
    [styles.heroContent]: heroContent,
    [styles.spaceBetween]: spaceBetween
  }, className);

  const internal = (
    <>
      {divider && divider}
      {childrenBefore}

      {backgroundVideo && (
        <div className={styles.backgroundVideo} style={dynamic}>{backgroundVideo}</div>
      )}
      {noInner ? (
        children
      ) : sticky ? (
        <div className={styles.inner}>
          <div className={styles.stickyInner}>{children}</div>
        </div>
      ) : (
        <div className={`${styles.inner} ${flexFix && styles.flexFix}`}>{children}</div>
      )}
      {backgroundImage ? header ? (
        <div style={dynamic} className={styles.backgroundImage}>
          <ParallaxImage>
            <GatsbyImage
              image={backgroundImage.gatsbyImageData}
              alt={backgroundImage.title || backgroundAlt || ""}
            />
          </ParallaxImage>
        </div>
      ) : (
        <div style={dynamic} className={styles.backgroundImage}>
          <GatsbyImage
            image={backgroundImage.gatsbyImageData}
            alt={backgroundImage.title || backgroundAlt || ""}
          />
        </div>
      ) : null}
      {backgroundImageSrc ? header ? (
        <div style={dynamic} className={styles.backgroundImage}>
          <ParallaxImage>
            {backgroundImageSrc}
          </ParallaxImage>
        </div>
      ) : (
        <div style={dynamic} className={styles.backgroundImage}>
          {backgroundImageSrc}
        </div>
      ) : null}
      {childrenAfter}
    </>
  );

  return header ? (
    <header className={classes} style={style} id={id}>
      {internal}
    </header>
  ) : (
    <section className={classes} style={style} id={id}>
      {internal}
    </section>
  );
};

export default Section;
